<template>
  <div class="lg:w-1/2 flex items-center justify-center lg:order-1 order-2">
    <img class="my-8 lg:h-1/2 h-28" :src="require(`../assets/${imgSrc}`)" />
  </div>
</template>
<script>
export default {
    name:"CustomerColLeft",
    props:["imgSrc"]
}
</script>
