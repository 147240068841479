<template>
  <div
    id="churchil"
    class="bg-white h-full container flex flex-col items-center mx-auto"
  >
    <Header text="Trent Controls" />
    <div class="w-full h-full flex bg-white lg:p-12">
      <div class="h-full w-full flex lg:flex-row flex-col">
        <CustomerColLeft imgSrc="TrentControls.jpg" />
        <CustomerColRight link="https://www.dropbox.com/sh/2z1p8524tu6yitd/AADT7QgL3e82zkfOjx3ORrnAa?dl=0" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import CustomerColRight from "../components/CustomerColRight.vue";
import CustomerColLeft from "../components/CustomerColLeft.vue";

export default {
  name: "TrentControls",
  components: { Header, CustomerColRight, CustomerColLeft },
};
</script>
