<template>
  <div
    id="register"
    class="bg-white h-full container flex flex-col items-center mx-auto"
  >
    <Header text="Password Reset"/>
    <div class="w-full h-full flex bg-white lg:p-12">
      <div class="h-full w-full flex lg:flex-row flex-col">
        <div
          class="lg:w-1/2 flex items-center justify-center lg:order-1 order-2"
        >
          <img class="my-8" src="../assets/Logo_Tall.png" />
        </div>
        <div
          class="lg:w-1/2 flex flex-col justify-center lg:order-2 order-1"
          style="background-color: #ffd67a"
        >
          <div class="flex flex-col p-6">
            <div class="my-6">
              <input
                v-model="email"
                type="text"
                class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="email"
                placeholder="Email"
              />
            </div>
            <div class="my-6 text-center lg:text-left">
              <button
                @click="sendPaswordResetEmail"
                type="button"
                class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Send Request
              </button>
              <p class="text-sm font-semibold my-4 pt-1">
                Don't have an account?
                <router-link
                  to="/register"
                  class="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-400 ease-in-out"
                  >Register</router-link
                >
              </p>
              <p class="text-sm font-semibold my-2 pt-1 mb-0">
                Return To Sign In?
                <router-link
                  to="/"
                  class="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
                  >Sign In</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPaswordReset } from "../Firebase/firebase.js";
import Header from "../components/Header.vue";

export default {
  name: "Password",
  components: { Header },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async sendPaswordResetEmail() {
      const success = await requestPaswordReset(this.email);
      if (success) {
        alert(`A Reset Email Link Has Been Sent To: ${this.email}`);
      } else {
        alert(`Error Finding: ${this.email}\nPlease Check Details`);
      }
    },
  },
};
</script>
