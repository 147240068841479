<template>
  <div
    class="lg:w-1/2 flex flex-col justify-center items-center lg:order-2 order-1 lg:py-0 py-12"
    style="background-color: #ffd67a"
  >
    <DocLink :link="link" />
  </div>
</template>
<script>
import DocLink from "./DocLink.vue";
export default {
  name: "CustomerColRight",
  components: { DocLink },
  props: ["link"],
};
</script>
