<template>
  <div class="flex flex-col justify-center items-center p-0 m-0">
    <div class="text-2xl mb-6">Dropbox Link</div>
    <div class="mb-12 text-xl">
      <a
        :href="link"
        class="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out"
        >Documents</a
      >
    </div>
    <div>
      <button
        @click="Logout"
        type="button"
        class="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
      >
        Logout
      </button>
    </div>
  </div>
</template>
<script>
import { logout } from "../Firebase/firebase.js";
export default {
  name: "DocLink",
  props: ["link"],
  methods: {
    async Logout() {
      await logout();
      localStorage.removeItem("ces-uid");
      this.$router.push(`/`);
    },
  },
};
</script>
