<template>
  <div
    id="churchil"
    class="bg-white h-full container flex flex-col items-center mx-auto"
  >
    <Header text="NGF" />
    <div class="w-full h-full flex bg-white lg:p-12">
      <div class="h-full w-full flex lg:flex-row flex-col">
        <CustomerColLeft imgSrc="NGF_LOGO.jpg" />
        <CustomerColRight link="https://cornfieldeng-my.sharepoint.com/:f:/g/personal/chris_cornfieldeng_co_uk/EvvjrzUzbBFGlDf8l7-pOSIBmlkxWPzRG4krkGjlvs19ag?e=xdcP87" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import CustomerColRight from "../components/CustomerColRight.vue";
import CustomerColLeft from "../components/CustomerColLeft.vue";

export default {
  name: "NGF",
  components: { Header, CustomerColRight, CustomerColLeft },
};
</script>
